.root {
  background: #fafafa;
}

.container-frame {
  width: 100%;
  min-width: 780px;
  height: -webkit-fill-available;
  min-height: 510px;
}

.close-button {
  position: absolute !important;
  right: 8px;
  top: 8px;
}

.color-button {
  color: #fff !important;
}

.container-viewer {
  overflow-y: hidden !important;
  height: calc(100vh - 48px) !important;
  padding: unset !important;
  background: #3d3d3d;
}

.no-margin-top {
  margin-top: 0px !important;
}

.zoom-text {
  width: 55px !important;
  margin: 0px !important;

  .MuiOutlinedInput-root {
    border-radius: 0%;
  }

  .MuiOutlinedInput-inputMarginDense {
    padding: 4px;
    font-size: 12px;
    text-align: center;
  }
}

.page-text {
  width: 30px !important;
  margin: 0px !important;

  .MuiOutlinedInput-root {
    border-radius: 0%;
  }

  .MuiOutlinedInput-inputMarginDense {
    padding: 4px;
    font-size: 12px;
    text-align: center;
  }
}

@media (max-width: 960px) {
  .no-margin-top {
    margin-top: 0px !important;
  }
}