.drop-zone {
  border: 1px dashed #5C7BF0;
  background: rgba(33, 70, 183, 0.1) !important;
  margin-bottom: 15px;

  strong {
    color: #5C7BF0 !important;
  }

  .button-drop-zone {
    height: 45px;
  }
}

// .button-drop-zone-position {
//   margin-bottom: -200px !important;
// }

.ItemFile {
  background: rgba(137, 165, 255, 0.1) !important;
  // height: 60px;
  border-radius: 10px;
  padding-top: 12px;
  padding-bottom: 12px;

  .file-item-remove {
    min-width: 0px !important;
    width: 30px !important;
    margin-top: -5px;
    margin-right: -50px;
  }

  .card-business-user-add {
    min-width: 0px !important;
    width: 100px !important;
    height: 42px !important;
    margin-top: -11.3px;
    right: -10 !important;
  }

  .circle-user {
    background: darkgray;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    padding-top: 5px;
    display: inline-block;
    color: #fff;
  }

  .circle-add {
    margin-left: -8px;
  }

}

.menu-size>.MuiMenu-paper {
  width: 350px !important;
}

.circle-user {
  background: darkgray;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  padding-top: 5px;
  display: inline-block;
  color: #fff;
}

.text-danger>small {
  color: #F44336 !important;
}

// .firm {
//   // margin-bottom: -65px !important;
// }
