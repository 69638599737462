.table-business {

  .name-center {
    margin-top: 2px;
  }

  .folder {
    color: rgba(92, 123, 240, 0.8);
  }

  .folder-size {
    color: rgba(92, 123, 240, 0.8);
    font-size: 50px;
  }

  .text {
    margin-top: 15px;
  }

  .user-icon {
    width: 25px;
    height: 25px;
    background: #1E88E5;
    border-radius: 50px;
    color: #fff;    
  }

  .text-icon {
    padding-left: 8px;
  }

  .text-detail {
    color: #5C7BF0;
    font-weight: 600;
  }

  .no-folder {
    margin-top: 50px;
    text-align: center !important;
    width: 100%;
  }

  .no-file-select {
    height: 400px;
  }
}

.card-right {
  box-shadow: 0px 2px 1px -1px 
  rgba(0,0,0,0.2), 0px 1px 1px 0px 
  rgba(0,0,0,0.14), 0px 1px 3px 0px 
  rgba(0,0,0,0.12) !important;
}