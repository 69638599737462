.DashboardCard {

  .card {
    height: 100px;
  }

  .icon {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50px;
  }

  .number-container {
    color: #fff;
    font-weight: bold;
    margin-top: -4px !important;
  }

  &-listItemText {
    overflow-wrap: anywhere;
  }
}