.MuiBreadcrumbs-separator {
  color: #5C7BF0 !important;
}

.MuiBreadcrumbs-separator svg {
  font-size: 0.9rem !important;
}

.MuiBreadcrumbs-li a {
  text-decoration: none !important;
  font-size: 1rem !important;
  color: #5C7BF0 !important;
}

.MuiBreadcrumbs-li span {
  font-size: 1rem !important;
  color: #5C7BF0 !important;
}

.Breadcrumbs {
  margin-top: 20px;
  margin-bottom: 20px;
}

.TagsFilter {
  margin-top: 5px;
}

.details-document-button {
  position: fixed !important;
  bottom: 10px !important;
  right: 10px !important;
  color: #fff;
}

.MuiDialog-paper {
  overflow: hidden !important;
  min-width: 500px !important;
  // max-width: 600px !important;
  // height: 90% !important;
}