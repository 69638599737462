.BusinessGroup {
  .card {
    height: 70px;
    background: white;
  }

  .icon {
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }

  .text {
    font-weight: bold;
  }

  .badge>span {
    margin-right: 25px;
    background: #4CC6FF !important;
  }

  .badge-no-space>span {
    background: #4CC6FF !important;
  }

  .badge-secondary>span {
    background: #FCBC19 !important;
  }

  a:link,
  a:visited,
  a:active {
    text-decoration: none;
  }

  .action-area {
    font-family: 'Rubik', sans-serif !important;
    font-size: 1rem !important;
  }
}