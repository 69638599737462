.notification {

	.notification-card {
		margin-top: -25px;
	}

	.document-text {
		color: #5C7BF0;
	}

	.ago-text {
		color: #8ea6fc;
	}

	.user-icon {
		width: 30px;
		height: 30px;
		background: red;
		border-radius: 40px;
		text-align: center;
		color: white;
		font-size: 22px;
		padding-top: 1.5px;
		margin-left: 5px;
	}

	.notification-button {
		margin-top: 16px !important;
		margin-right: -25px !important;
		color: #5C7BF0;
	}

}

.notification-dialog>.MuiDialog-container>div {
	// width: 450px;
	overflow-y: inherit;
}

.notification-send {
	background: #5C7BF0 !important;
	width: 410px !important;
	margin: 20px !important;
	color: white !important;
	margin-bottom: 15px !important;
	margin-top: 15px !important;
}

.badge-red>span {
	background: #F44336 !important;
}

.user-icon-lg {
	background: gray;
	width: 35px;
	height: 35px;
	border-radius: 20px;
	text-align: center;
	font-size: 18px;
	color: white;
	padding: 8px;
}

.tab-size {
	width: 450px;
	height: 450px;
	overflow-y: auto;
	overflow-x: hidden;
}

.close-button-position {
	position: absolute;
	right: 5px;
	top: 5px;
}

.close-button-position>button {
	width: 38px;
	background: white;
	border-color: transparent;
}

.center-text {
	padding-left: 150px;
}