.template-business-card {
  .card {
    height: 70px;
    background: white;
  }
  
  .icon {
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .text {
    font-weight: bold;
  }

  .action-area {
    font-family: 'Rubik', sans-serif !important;
    font-size: 1rem !important;
  }
}

@media(max-width: 768px) {
  .template-business-card {
    .icon {
      width: 30px;
      height: 30px; 
    }
  }
}
