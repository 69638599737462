.terms-and-condition-size {
  overflow: auto !important;
}

.terms-and-condition-buttons-container {
  padding: 15px;
}

.terms-and-condition-buttons-space {
  margin-right: 10px !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #F44336 !important;
}

.safety-level-color-low>.MuiLinearProgress-barColorPrimary {
  background-color: #F44336 !important;
}

.safety-level-color-low {
  color: #F44336 !important;
}

.safety-level-color-medium>.MuiLinearProgress-barColorPrimary {
  background-color: #FF9800 !important;
}

.safety-level-color-medium {
  color: #FF9800 !important;
}

.safety-level-color-tall>.MuiLinearProgress-barColorPrimary {
  background-color: #03A9F4 !important;
}

.safety-level-color-tall {
  color: #03A9F4 !important;
}

.safety-level-color-high>.MuiLinearProgress-barColorPrimary {
  background-color: #38B349 !important;
}

.safety-level-color-high {
  color: #38B349 !important;
}

.progress-bar {
  height: 8px !important;
  border-radius: 10px !important;
}

.link-color {
  color: #7A92C5 !important;
}

.icon-powered-by {
  width: 75px;
  margin-bottom: 10px;
}

.onboarding-right-panel {
  background: #5C7BF0;
  background-size: auto 100%;
  background-position-x: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 0;
  height: 100%;
}

.right-panel-logo {
  max-width: 55%;
  margin-top: 15% !important;
  position: absolute;
}

.right-panel-image-container {
  position: absolute;
  bottom: 0;
}

.right-panel-image-container img {
  width: 100%;
  max-height: 420px;
  z-index: 0;
}