.ViewFile {

  width: 100% !important;
  height: 100% !important;
  overflow-x: hidden;

  .dialog-size {
    width: 800px !important;
    height: 350px !important;
  }

}

.table-item {
  cursor: pointer;
}

.table-item.Mui-selected {
  background-color: rgba(158, 158, 158, 0.38) !important; 
}


.modal-size>.MuiDialog-container>div {
  max-width: none !important;
}

.tree-title {
  background: #1e88e5;
}

.tree-container {
  background: #1e88e5;
  // height: 123%;
}

.scroll {
  overflow-y: auto;
  height: 350px !important;
}

.margin {
  padding-top: 35px !important;
  padding-bottom: 0px !important;
  padding-right: 20px !important;
}
