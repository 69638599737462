.SelectorContainer {
  background: rgba(33, 70, 183, 0.05);
  border-radius: 12px;
  max-height: 231px;
}

.selectColumn {
  width: 87% !important;
  margin-bottom: 10px;
}

.menuFilterContent {
  padding: 10px;
}