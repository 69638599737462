.CardBusiness {
  .card-business-user-img {
    color: #5C7BF0;
    width: auto;
    height: auto;
    border-radius: unset;
  }

  .card-business-user {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    color: white;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif !important;
    border: solid 2px white;
  }

  .card-business-user-position {
    margin-left: -10px !important;
  }

  .card-business-user-text {
    padding-top: 20%;
    text-align: center;
  }

  .card-business-user-add {
    min-width: 0 !important;
    width: 30px !important;
    height: 30px !important;
  }

  .card-business-user-add-container {
    margin-top: 4px !important;
  }

  .card-margin {
    border: solid 1px rgba(92, 123, 240, 0.3) !important;
    border-radius: 12px;
  }

  .disabled {
    color: #cccccc
  }

  .disabled-background {
    background: #cccccc
  }
}