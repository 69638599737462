.Calendar {
  .button-border {
    border: 1px solid #5c7bf0 !important;
    color: #5c7bf0 !important;
  }

  .header {
    margin-bottom: 15px;
  }

  .scroll-calendar {
    overflow-y: auto;
  }

  .load-alert {
    width: 235px;
  }

  .no-scroll {
    overflow: hidden !important;
  }

  .margin-calendar {
    margin-bottom: 40px;
  }

  .MuiDialog-paper {
    overflow: hidden !important;
    width: 435px !important;
    max-width: 600px !important;
    height: 90% !important;
  }

  .card-color {
    color: white !important;
  }

  .container {
    // margin-left: -15px;
    // width: 365px !important;
  }

  .card-activity {
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
    border-radius: 5px;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
}

.Activity {
  .header {
    padding-top: 5px;
    background: rgba(92, 123, 240, 0.05);
    color: #5c7bf0 !important;
  }

  .button-options {
    width: 30px !important;
    height: 30px !important;
    min-width: 0px !important;
    margin-right: -10px !important;
    padding-bottom: 10px !important;
  }

  .user {
    padding-top: 4px;
    width: 30px !important;
    height: 30px !important;
    text-align: center;
    color: white;
    display: inline-block;
    border-width: 2px;
    border-style: solid;
    border-color: white;
    border-radius: 50px;
  }

  .user-1 {
    background: #0288d1;
  }

  .user-2 {
    background: #3f51b5;
  }

  .user-3 {
    background: #9c27b0;
  }

  .user-mount {
    margin-left: -8px;
  }

  .more-users {
    background: #e65100;
  }
}

.activity-detail > div > div {
  width: 750px;
  max-width: 850px !important;
  height: 470px;
}

.table-size-scroll > .MuiTableContainer-root {
  height: 140px;
}

.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.no-td {
  padding: 0px !important;
  border-bottom: 0px !important;
}

.no-calendar {
  display: none;
}

.space {
  height: 95px;
}

.header-position {
  position: absolute;
  width: 418px;
  background: white;
  z-index: 10000;
  margin-top: -16px;
  margin-left: -16px;
  margin-right: -10px;
  padding: 10px;
}

.tab-position {
  position: absolute;
  width: 418px;
  background: white;
  z-index: 10000;
  margin-top: 44px;
  margin-left: -16px;
}

.h-75 {
  height: 75px;
}

.circle-user {
  cursor: pointer;
}
