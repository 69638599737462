.BusinessFolders {

  cursor: pointer;

  .card {
    height: 64px;
  }

  .icon {
    font-size: 30px;
    color: rgba(92, 123, 240, 0.8);
  }

  &-folderDisabled {

    cursor: default;

    .card {
      background-color: rgba(0, 0, 0, 0.04);

      svg {
        color: #f44336;
      }

    }

    strong {
      color: gray;
    }

  }

}

.start>div {
  background: lightgray !important;
}

.finish>div {
  background: #4CAF50 !important;
}

.process>div {
  background: rgb(244, 67, 54) !important;
}


