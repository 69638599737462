@media (min-width: 1280px) {
  .home .MuiContainer-maxWidthLg {
    max-width: none !important;
  }
}

.main-container {
  min-height: calc(100vh - 20px) !important;
}

.footer {
  display: flex;
  min-width: calc(100% + 80px);
  margin-left: -40px;
  height: 75px;
  margin-top: auto;
  padding-top: 15px;
}

.container-footer {
  background: #F2F4F9;
}
