.label-metodo {
  color: black;
  font-weight: bold;
  font-size: small;
}

.select {
  width: 100px;
}

.endorsement-label {
  margin: 0;
  font-weight: bold !important;
  color: #5C7BF0 !important;
}