.MuiDialog-paperWidthSm {
  max-width: 700px !important;
}

.MuiDialogContent-root:first-child {
  padding-top: 0px !important;
}

.doc {
  padding-left: 10px;
}