.property-dialog {
  .MuiDialog-paper {
    max-width: 300px !important;
    min-width: 280px !important;
  }
}

.dialog-new-business {
  .MuiDialog-paper {
    width: 800px !important;
  }
}