.VerticalCenterDiv {
  .outer {
    display: table;
    top: 0;
    left: 0;
  }
  
  .middle {
    display: table-cell;
    vertical-align: middle;
  }
  
  .inner {
    margin-left: auto;
    margin-right: auto;
  }
}