.popup-user>div>div {
  max-width: none !important;
  width: auto !important;
  min-width: 600px !important;
  min-height: 500px !important;
}

.popup-user {

  .card-zone {
    background: #e0e0e0;
    // margin-left: 20px;
    // margin-right: 20px;
    margin-bottom: 10px;
  }

  .card {
    margin: 5px -10px 10px;
    height: 80px;
  }

  .circle-user {
    text-align: center !important;
  }

  .pl-2 {
    padding-left: 25px;
  }

  .pt-2 {
    padding-top: 25px;
  }

  .combobox {
    margin-left: -6px !important;
  }

  .MuiAutocomplete-clearIndicator {
    display: none !important;
  }

  .checkbox {
    position: absolute;
    top: -22px;
    left: -7px;
  }

  .top-button {
    margin-top: 23px;
    margin-left: -5px;
    background: transparent;
    border: none;
  }

  .bottom-button {
    margin-top: 5px;
    margin-left: -5px;
    background: transparent;
    border: none;
  }

}

.MuiMenu-paper {
  //top: 130px !important;
}

.info-text {
  margin-left: 4rem !important;
  margin-top: -1rem !important;
}

.bottom-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 60px;
}

.bottom-options-between {
  justify-content: space-between;
}

.no-signers-available {
  display: flex;
  justify-content: center;
  min-width: 400px;
}

.disabled {
  color: rgba(0, 0, 0, 0.38);
}