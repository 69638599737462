@media (min-width: 960px) {
  .selector-roles {
    margin-left: 20px !important;
  }
}

@media (max-width: 960px) {
  .MuiGrid-grid-xs-12 {
    margin-left: 0px !important;
    // margin-top: 20px !important;
  }

  .padding-form {
    padding: 10px !important;
  }
}

.Selector {
  background: rgba(33, 70, 183, 0.05);
  border-radius: 12px;
  overflow: auto;
  padding: 16px;

  &-roles {
    max-height: 400px;
  }
}

.Mui-checked {
  color: #5C7BF0 !important
}

.padding-form {
  padding: 30px;
}
